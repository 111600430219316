<template>
  <div class="container">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane
        :label="item.rolename + '权限'"
        v-for="item in tableData"
        :key="item.rolecode"
        :name="item.rolecode"
      >
        <div class="toolbar">
          <el-button
            type="primary"
            size="medium"
            v-on:click="editDetails"
            class="margin-15"
            >修改{{ rolename }}权限</el-button
          >
        </div>
        <el-table :data="menuList" border size="small" style="width: 100%">
          <el-table-column type="index" label="#" width="50"> </el-table-column>
          <el-table-column prop="name" label="权限名称"> </el-table-column>
          <el-table-column prop="menulevel" label="权限等级"> </el-table-column>
          <el-table-column prop="menuicon" label="图标"></el-table-column>
          <el-table-column prop="url" label="url"> </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <role-admin
      :rolecode="rolecode"
      ref="admin"
      :tableDialog="tableDialog"
      :menuList="menuList"
      :is="isComponents"
      :rolename="rolename"
      :isComponents="isComponents"
      @ROLESUCCESS="rolechange"
    ></role-admin>
  </div>
</template>

<script>
import api from "../../api";
import roleAdmin from "./components/role-admin";
export default {
  components: {
    roleAdmin,
  },
  data() {
    return {
      isComponents: "",
      rolecode: "",
      form: {
        name: "",
        menulevel: "-1",
        pid: "-1",
        url: "",
        ordernum: 1,
        menuicon: "",
      },
      formRole: {
        name: [{ required: true, message: "请输入权限名称", trigger: "blur" }],
        url: [{ required: true, message: "请输入url", trigger: "blur" }],
      },
      tableDialog: { show: false },
      list: [],
      parentList: [],
      roleItem: {},
      detail: {},
      params: {
        page: 1,
        pageSize: "1000",
      },
      pageIndex: 1,
      pageSize: "8",
      totalData: 0,
      tableData: [],
      menuList: [],
      menuDetail: [],
      rolename: "",
      activeName: 0,
      viewDetailDialog: false,
      viewDetailObj: null,
      editDetailObj: "",
      editDetailDialog: false,
    };
  },

  mounted() {
    this.loginData = JSON.parse(localStorage.getItem("logindata"));
    this.getRoleList();
  },
  methods: {
    getRoleList() {
      api
        .getRoleList({
          categorycode: this.loginData.categorycode,
          userlevel: this.loginData.userlevel,
          rolename: "",
          iseffective: "",
          page: this.pageIndex,
          pageSize: this.pageSize,
        })
        .then((res) => {
          if (res.data.success === "1") {
            this.tableData = res.data.data.list;
            this.activeName = this.tableData[0].rolecode;
            this.rolename = this.tableData[0].rolename;
            this.rolecode = this.tableData[0].rolecode;
            this.getRoleMenuList();
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
        })
        .catch(() => {
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
        });
    },
    async getRoleMenuList() {
      await this.$https.limitService
        .getRoleMenuList({ rolecode: this.rolecode })
        .then((res) => {
          this.menuList = res;
        });
    },
    handleClick() {
      this.rolecode = this.activeName;
      this.roleItem = this.tableData.find((e) => e.rolecode == this.activeName);
      this.rolename = this.roleItem.rolename;
      this.getRoleMenuList();
    },
    editDetails() {
      this.tableDialog.show = true;
      this.isComponents = "roleAdmin";
    },
    del(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await this.$https.limitService.delMenu({ id: row.id });
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.initList();
      });
    },
    rolechange() {
      this.isComponents = "";
      this.tableDialog.show = false;
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.initList();
    },
    handleSizeChange(val) {
      this.params.page = val;
      this.initList();
    },
  },
};
</script>

<style scoped lang="scss">
.toolbar {
  margin: 15px 0;
}
.container {
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.footer {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}
</style>
