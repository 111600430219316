<template>
  <el-dialog
    append-to-body
    title="权限管理"
    :visible.sync="tableDialog.show"
    @close="close"
  >
    <el-tree
      :data="treeList"
      show-checkbox
      node-key="id"
      :props="defaultProps"
      ref="tree"
      :default-checked-keys="this.checkList"
      :default-expand-all="true"
      :check-strictly="true"
      :expand-on-click-node="false"
    >
    </el-tree>
    <!-- <el-table
      :data="list"
      height="400"
      border
      size="small"
      ref="multipleTable"
      @selection-change="handleSelectionChange"
      style="width: 100%; height: 400px; overflow: scroll"
    >
      <el-table-column type="selection" width="55" :checked="true">
      </el-table-column>
      <el-table-column prop="name" label="权限名称"> </el-table-column>
      <el-table-column prop="createtime" label="创建时间"> </el-table-column>
    </el-table> -->
    <span slot="footer">
      <el-button plain type="success" size="medium" v-on:click="updateRoleName"
        >确认修改</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    tableDialog: {
      type: Object,
      default: () => {},
    },
    menuList: {
      type: Array,
      default: () => [],
    },
    rolename: { type: String, default: "" },
    rolecode: { type: String, default: "" },
    isComponents: { type: String, default: "" },
  },
  data() {
    return {
      list: [],
      checkList: [],
      params: {
        page: 1,
        pageSize: "1000",
      },
      treeList: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  mounted() {
    this.initList();
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    nodeclick(e) {
      console.log(e);
    },
    close() {
      this.$emit("ROLESUCCESS");
    },
    getParentsId(k) {
      return this.list.find((e) => e.id == k);
    },
    async updateRoleName() {
      const list = this.$refs.tree.getCheckedNodes(); //获取当前选中list
      let menus = [];
      list.map((e) => {
        menus.push(e.id);
        menus.push(e.pid); // 遍历出2级
        if (this.getParentsId(e.pid)) {
          menus.push(this.getParentsId(e.pid).pid); //如果选3级遍历出1级
        }
      });
      menus = [...new Set(menus)].join(","); //去重
      console.log(menus);
      // let menus = this.checkList.map((e) => e.id).join(",");
      let params = {
        menus: menus,
        rolecode: this.rolecode,
        rolename: this.rolename,
      };
      console.log(params);
      await this.$https.limitService.updateRole(params).then(() => {
        this.$message({
          message: "修改成功",
          type: "success",
        });
      });
      this.$parent.getRoleMenuList();
      this.$emit("ROLESUCCESS");
    },
    handleSelectionChange(val) {
      console.log(val);
      this.checkList = val;
    },
    async initList() {
      await this.$https.limitService
        .getMenuPageList(this.params)
        .then((res) => {
          let checkList = [];
          let treeList = [];
          let childrenList = [];
          let sunList = [];
          const levelList1 = [];
          this.list = res.list;
          this.detail = res;
          res.list.map((e) => {
            e.label = e.name;
            levelList1.push(e.id);
            e.children = [];
            if (e.menulevel == 1) {
              treeList.push(e);
            } else if (e.menulevel == 2) {
              childrenList.push(e);
            } else if (e.menulevel == 3) {
              console.log(1);
              sunList.push(e);
            }
          });
          treeList.map((e) => {
            childrenList.map((v) => {
              if (e.id == v.pid) {
                e.children.push(v);
                sunList.map((k) => {
                  if (v.id == k.pid) {
                    v.children.push(k);
                  }
                });
              }
            });
          });
          this.treeList = treeList;
          this.menuList.map((e) => {
            //选择
            let list = this.list.find((v) => {
              return v.id == e.id; //便利出选中的list
            });
            if (list) checkList.push(list);
          });
          this.$nextTick(() => {
            this.checkList = checkList.map((e) => e.id); //选中的list
            // this.toggleSelection(this.checkList);
          });
        });
    },
  },
};
</script>

<style>
</style>